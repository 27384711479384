<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Content Plans'" :title="'Content Plans'">
      <div class="list__buttons">
        <MainButton @click.native="handleCreate()">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div class="list__statuses">
          <div
            v-for="item in templatesStatusesList"
            :key="item.id"
            :style="{
              order: item.id == 2 ? 1 : item.id == 1 ? 2 : item.id == 8 ? 3 : item.id == 5 ? 4 : 5,
            }"
          >
            <div
              v-if="item.id == 2 || item.id == 1 || item.id == 8 || item.id == 5"
              class="list__status"
            >
              {{ item.name }} <span>{{ item.count }}</span>
            </div>
          </div>
        </div>
        <div v-if="plansList.length">
          <CustomTable
            v-if="plansList.length"
            :data="plansList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handleFilter="handleFilter"
            :handleCopy="handleDetail"
            :direction="directionSort"
            :order="orderSort"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="plans.lastPage"
              :page="page"
              :dataList="plans"
              :urlList="'PLANS_LIST'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Content Plans found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Content Plan - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>

    <MainModal v-if="createModal" :on-close="handleCloseCreateModal" :modal-center="true">
      <div class="list__confirm">
        <p class="form-create__label">Month</p>
        <div class="input-container">
          <MainSelect
            :id="'month'"
            :placeholder="'Choose'"
            :option-list="monthList"
            :set-value="formDataToSend"
            :value="formDataToSend.month"
            :error-messages="validationErrors['month']"
          />
        </div>

        <p class="form-create__label">Year</p>
        <div class="input-container">
          <MainSelect
            :id="'year'"
            :placeholder="'Choose'"
            :option-list="yearList"
            :set-value="formDataToSend"
            :value="formDataToSend.year"
            :error-messages="validationErrors['year']"
          />
        </div>

        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseCreateModal" :button-cancel="true"
              >CANCEL</MainButton
            >
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleCreatePlan">CREATE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">
        Content Plan {{ deleteRow.name }} successfully deleted.
      </div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import templateApi from "~/api/templates";
import moment from "moment";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";

export default {
  name: "PlansList",
  metaInfo: {
    title: "Content Plans List",
  },
  data() {
    return {
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: false,
      orderSort: "id",
      createModal: false,
      validationErrors: [],
      formDataToSend: {
        month: null,
        year: null,
      },
      monthList: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      yearList: [],
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "month",
            to: "Month",
          },
          {
            from: "year",
            to: "Year",
          },
        ],
        customOrder: ["id", "month", "year", "null"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    MainSelect,
  },
  mounted() {
    this.getStatuses();
    this.getList();

    for (let i = 0; i <= 2; i++) {
      const year = +moment().format("YYYY") + i;
      this.yearList.push({ id: year, name: `${year}` });
    }
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "plans", "plansList"]),
    ...mapState("references", ["templatesStatusesList"]),
    page() {
      return +this.$route.params.page;
    },
  },
  methods: {
    getStatuses() {
      this.$store.dispatch("references/getTemplatesStatuses");
    },
    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      this.$store.dispatch("templates/getPlansList", url);
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDetail(row) {
      this.$router.push({
        path: ROUTE.PLANS_ITEMS.replace(":id", `${row.id}`),
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.SEO_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleCreate() {
      this.createModal = true;
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}`;
      this.$store.commit("statics/setLoading", true);
      staticApi
        .deleteSeo(url)
        .then((res) => {
          this.$store.commit("statics/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("statics/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },

    handleCloseCreateModal() {
      this.createModal = false;
    },

    handleCreatePlan() {
      const data = {};
      if (this.formDataToSend.month) {
        data.month = this.formDataToSend.month.id;
      }
      if (this.formDataToSend.year) {
        data.year = this.formDataToSend.year.id;
      }
      templateApi
        .createPlan(data)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseCreateModal();
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__buttons {
    display: flex;
  }
  &__search {
    min-width: 250px;
    margin-right: 20px;
  }
  &__statuses {
    display: flex;
    flex-wrap: wrap;
  }

  &__status {
    margin-right: 10px;
    padding: 10px 20px;
    background: rgb(228, 228, 228);
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 700;

    span {
      margin-left: 15px;
      padding: 10px;
      background: rgb(168, 168, 168);
      border-radius: 10px;
    }
  }
}
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr 1fr !important;
}
</style>
